.userlist {
  position: absolute;
  left: 8px;
  top: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;

  border-radius: 4px;
  max-width: 20vw;
  list-style-type: none;
  overflow: scroll;
  max-height: 100%;

  .user {
    input {
      margin: 2px;
    }
    padding: 2px;

    &.no_data {
      color: #666666;
    }

    &.error {
      color: red;
    }

    &.loading {
      color: #333;
    }
  }

  .exportButton {
    display: block;
    padding: 4px;
    margin: 8px auto;
  }
}
