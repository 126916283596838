.all_entries {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: scroll;

  .entry {
    position: relative;
    background-color: #eeeeee;
    padding: 8px;
    margin: 8px;
    border-radius: 8px;
    box-shadow: 0 0 2px 0 #666666;
    display: flex;
    line-height: 1.3em;

    .created {
      flex: 6;
      color: #666666;
      font-size: smaller;
      text-align: right;
      display: inline;
    }

    .comment {
      flex: 10;
      display: inline;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .delete {
      position: absolute;
      top: -4px;
      right: -4px;
      width: 16px;
      height: 16px;

      border: 0;
      color: white;
      background-color: #990000;
      font-weight: bold;
      border-radius: 7px;
      padding: 2px;
      font-size: 10px;
    }
  }
}
