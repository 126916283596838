* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body, html, #root {
    height: 100%;
}

body {
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    overflow: hidden;
}

#bottom-ui {
    color: black;
    position: absolute;
    bottom: 32px;
    right: 2%;
    width: 96%;
    background: white;
    border-radius: 16px;
    border: 1px solid #ddd;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s, height 0.3s;
    opacity: 1;
}
