$close-size: 30px;

#close {
  position: absolute;
  top: -($close-size / 2);
  right: -8px;
  background-color: #9f9f9f;
  color: white;
  text-decoration: none;
  font-weight: bold;
  line-height: $close-size;
  width: $close-size;
  height: $close-size;
  border-radius: 100px;
  text-align: center;
  cursor: pointer;
}

#logout {
  border: 1px solid grey;
  border-radius: 40px;
  padding: 5px;
  background-color: white;
  position: absolute;
  top: 4px;
  right: 4px;
}
