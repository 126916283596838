.indicator_choice {
  display: block;
  font-size: 15px;
  background-color: #eee;
  padding: 12px;
  margin: 6px;
  border-radius: 18px;
  border: 0;
}

.buttons {
  display: flex;
}

.address {
  text-align: center;
  color: #919191;
  font-size: 8pt;
  margin-top: -4px;
  margin-bottom: 4px;
}

.observation_buttons {
  position: absolute;
  bottom: -10px;
  left: 4px;
  z-index: 1;

  button {
    border-radius: 10px;
    border: 0;
    padding: 3px 8px;
    margin: 2px;
    margin-left: 4px;

    background-color: #eeeeee;
    box-shadow: 0 0 2px 0 #666666;
    color: #666;
  }
}

.action {
  border: 0;
  color: white;
  border-radius: 7px;
  flex: 1;

  float: right;
  margin: 8px;
  padding: 16px;
  font-size: 20px;

  &.save {
    background-color: #0101bb;
  }
  &.delete {
    background-color: #880000;
  }
}
